<template>
  <div class="vld-parent cyb-login">
  <login-form>
    <template #misc-content>
      <h1><span>{{ $t('forgotpassword.miscTitle') }}</span></h1>
      <p>{{ $t('forgotpassword.miscSubtitle') }}</p>
      <p>{{miscInstructions[0]}}</p>
      <p>{{miscInstructions[1]}} <a href="https://www.cybelesoft.com/support/" target="_blank">{{ $t('forgotpassword.ourSupportTeam')}}</a>.</p>
    </template>
    <template #form>
    <Card class="p-fluid">
      <template #header><h1 class="active">{{ $t("forgotpassword.title") }}</h1></template>
      <template #content>
        <div class="p-grid">
          <div class="p-col">
            <div class="p-field p-mb-3">
              <InputNumber id="accountid" v-model="accId" mode="decimal" allowEmpty="true" :min="1" :useGrouping="false" :placeholder="$t('common.account_id')" autocomplete="account-id"/>
            </div>
             <field-error :value="v$.accountId"/>
          </div>
        </div>
        <div class="p-fluid">
          <div class="p-field">
            <InputText id="email" v-model="email" type="mail" :placeholder="$t('common.email')"/>
          </div>
           <field-error :value="v$.email"/>
          <div class="d-flex align-items-center">{{ $t("forgotpassword.remember_your_password") }}
            &nbsp;<router-link to="/login">{{ $t("forgotpassword.login_link") }}</router-link>
          </div>
        </div>
        <div class="p-mt-4">
          <div class="recaptchaContainer">
              <vue-recaptcha v-if="showRecaptcha" :siteKey="reCaptchaSiteKey" theme="light" :size="(compactCaptcha) ? 'compact' : 'normal'"
              @verify="reCaptchaVerified" @expire="reCaptchaExpired" @fail="reCaptchaFailed" ref="vueRecaptcha"></vue-recaptcha>
          </div>
        </div>
      </template>
      <template #footer>
        <field-error class="form-errmsg p-mb-2 text-center" id="msgerror" :value="errorMessage" />
        <Button :label="$t('forgotpassword.submit_button')" @click.prevent="handleSubmit($event)" :disabled="inProcess || !confirmed" />
      </template>
    </Card>
    </template>
  </login-form>
  </div>
  <CommonDialog ref="dialog" style="max-width: 35rem"></CommonDialog>
</template>

<script>
import { adminService } from "../services/admin.service";
import { ErrorHelper } from "../helpers/ErrorHelper";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import LoginForm from "../components/LoginForm.vue";
import FieldError from '../components/FieldError.vue';
import CommonDialog from '../components/CommonDialog.vue';
import vueRecaptcha from 'vue3-recaptcha2';
import { apiConfig } from '../config/backend-api';

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    LoginForm,
    FieldError,
    CommonDialog,
    vueRecaptcha
  },
  data() {
    return {
      errorMessage: "",
      accountId: "",
      email: "",
      showRecaptcha: false,
      isNotARobot: false,
      inProcess: false,
      miscInstructions: this.$t("forgotpassword.miscMessage").split("#")
    };
  },
  computed: {
    accId: {
      get: function() {
        return this.accountId === '' ? null : this.accountId;
      },
      set: function(value) {
        this.accountId = value;
      },
    },
    compactCaptcha() {
      return window.outerWidth < 500;
    },
    confirmed() {
      return this.isNotARobot || !this.showRecaptcha;
    },
  },
  mounted() {
    this.reCaptchaSiteKey = apiConfig.reCaptchaSiteKey;
    this.showRecaptcha = !!this.reCaptchaSiteKey;
  },
  validations() {
    return {
      accountId: { required },
      email: { required, email },
    };
  },
  methods: {
    gotoLogin() {
      this.$router.push({name: 'login'});
    },
    handleSubmit() {
      this.errorMessage = "";
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.inProcess = true;
        adminService
          .forgotPassword(this.accountId, this.email)
          .then(() => {
            this.inProcess = false;
            this.showMessage(this.$t('forgotpassword.successfullyMessage').split('#'), this.gotoLogin);
          })
          .catch((error) => {
            this.inProcess = false;
            this.errorMessage = ErrorHelper.getErrorMessage(error);
          });
      }
    },
    reCaptchaVerified() {
      this.isNotARobot = true;
    },
    reCaptchaInvalidate() {
      this.$refs.vueRecaptcha.reset();
      this.isNotARobot = false;
    },
    reCaptchaExpired() {
      this.reCaptchaInvalidate();
    },
    reCaptchaFailed() {
      this.reCaptchaInvalidate();
    },
      showMessage(msg, callback) {
      this.$refs.dialog.show({
        title: this.$t('forgotpassword.successfulTitle'),
        message: msg,
        icon: 'thin-check',
      }).then(() => {
        if (callback) callback();
      });
    },
  },
};
</script>